import { Webhook } from '@mui/icons-material'
import { Box, Skeleton, Typography } from '@mui/material'
import React from 'react'
import { MiscTypes, ParamsEnums } from '../../../enums'
import addUrlDataHoc from '../../../hoc/addUrlDataHoc.tsx'
import PreviewSection from './PreviewSection.tsx'
import { useCustomSelector } from '../../../utils/deepCheckSelector'
import AuthenticationsforSpecificplug from '../../../pages/authDataTable/AuthenticationsforSpecificplug.tsx'
import { useFetchPlugins } from '../../../react-query/allPluginsData/allPluginsDataQueries.ts'

/**
 * Renders the authentication component for a specific service.
 * @param {string} service - The service for which the authentication component is rendered.
 * @returns {JSX.Element} The authentication component.
 */
function renderAuthComponent(service: string): JSX.Element {
  return <AuthenticationsforSpecificplug selectedService={service} />
}

/**
 * Props for the PreviewSectionWrapper component.
 */
interface PreviewSectionWrapperProps {
  projectId: string
  orgId: string
  firstServiceId: string
  isEmbedUrl: boolean
}

/**
 * Wrapper component for the PreviewSection.
 * @param {PreviewSectionWrapperProps} props - The props for the component.
 * @returns {JSX.Element} The rendered PreviewSection component.
 */
function PreviewSectionWrapper({ projectId, orgId, firstServiceId, isEmbedUrl }: PreviewSectionWrapperProps): JSX.Element {
  const { currentIntegrationSettings, integrationConfig } = useCustomSelector((state) => ({
    currentIntegrationSettings: state.projects.embedProject[orgId]?.['active']?.[projectId]?.settings || {},
    integrationConfig:
      state.appInfo.mode === MiscTypes.EMBED_MODE
        ? state.projects.embedProject[orgId]?.['active']?.[projectId]?.settings?.config
        : state.appInfo.localConfiguration
  }))
  const { data: services, isLoading } = useFetchPlugins(orgId, [], [firstServiceId])
  const serviceData = services?.[firstServiceId]
  const homePageProps = isEmbedUrl
    ? {
        integrationServiceId: currentIntegrationSettings?.serviceId,
        authenticationComponent: (service: string) => renderAuthComponent(service),
        serviceType: currentIntegrationSettings?.serviceType,
        showServicesBeforeEventSelection: integrationConfig?.showServices,
        showEnabled: integrationConfig?.showEnabled,
        filterTrigger: integrationConfig?.filterTrigger,
        filterAction: integrationConfig?.filterAction,
        showTemplates: integrationConfig?.showTemplates,
        featuredCombinationType: integrationConfig?.showFeautedCombinations ? integrationConfig?.featuredCombinationType : false,
        heading: integrationConfig?.pageheading || 'Integrations',
        showEnabledApps: true,
        pagesubheading: integrationConfig?.pagesubheading
      }
    : {
        showEnabled: true,
        authenticationComponent: (plug) => renderAuthComponent(plug),
        integrationServiceId: firstServiceId,
        showSubHeading: false,
        heading: isLoading ? (
          <Skeleton variant='text' />
        ) : (
          <Box className='flex-start-center gap-2'>
            {serviceData?.iconurl ? <img src={serviceData?.iconurl} width={28} alt='' /> : <Webhook fontSize='24px' />}
            <Typography variant='h4'>{serviceData?.name || 'Webhook'}</Typography>
          </Box>
        ),
        filterTrigger: true,
        filterAction: true,
        featuredCombinationType: 'default',
        showServicesBeforeEventSelection: true,
        showEnabledApps: false,
        showTemplates: true
      }

  return <PreviewSection {...homePageProps} />
}

export default React.memo(
  addUrlDataHoc(React.memo(PreviewSectionWrapper), [
    ParamsEnums.orgId,
    ParamsEnums.projectId,
    ParamsEnums.scriptId,
    ParamsEnums.tabName,
    ParamsEnums.isTemplate,
    ParamsEnums.sectionKey,
    ParamsEnums.eventId,
    ParamsEnums.serviceId,
    ParamsEnums.firstServiceId,
    ParamsEnums.isEmbedUrl
  ])
)
