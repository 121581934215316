import { createAsyncThunk } from '@reduxjs/toolkit'
import { batch } from 'react-redux'
import { getOneFunction, optimizeCodeByAI, updateStep } from '../../api'
import { BlockTypes, Tabnames } from '../../enums'
// import { setFlowJsonAndUsedVariables } from '../flowJson/flowJsonSliceV2.ts'
import { getInfoParamtersFromUrl } from '../../utils/utilities'
import { setMetaDataForFlowJson } from '../flowJson/flowJsonSliceV2.ts'
import { updatedUsedVariables } from '../invocationV2/invocationSliceV2.ts'
import { updateAppInfoState } from '../appInfo/appInfoSlice.ts'
import { addScriptAndUpdateList } from '../scripts/scriptsSlice'
import { API_URL } from '../../config'
import { filterObjectByKey } from '../invocationV2/invocationSelectorV2'
import { processSupportedLibraryData } from '../../utils/aiUtility/common.tsx'
import { setKeyValueInstance } from './stepSliceV3.ts'
import { createCodeForAPI } from '../../components/saveButton/saveButtonV3.tsx'
import { getApiInstanceDataFromCode } from './stepReducerV3.ts'

export const setFunctionJsonThunkV3 = createAsyncThunk('stepsDataV3/setFunctionJson', async () => {
  const { scriptId, stepId, tabName } = getInfoParamtersFromUrl()
  const result = await getOneFunction({ scriptId, functionId: stepId })
  // dispatch(updateAppInfoState({ currentStepType: result.data.data.type }))
  if (!result.data.data.type) throw new Error('Invalid api call!')
  const isPublishedTab = tabName === Tabnames.PUBLISH
  if (isPublishedTab) {
    result.data.data.code = result?.data?.data?.published_code
  }
  return result?.data?.data
})

export const createOrUpdateFunctionThunk = createAsyncThunk('stepDataV3/createOrUpdateFunctionThunk', async (payload, { dispatch }) => {
  const { scriptId, slugName } = getInfoParamtersFromUrl()
  const id = payload?.data?.type === BlockTypes.COMMENT ? payload?.data?.stepId : payload?.slugName || slugName || payload?.data?.type
  const response = await updateStep(scriptId, id, payload?.data)
  batch(() => {
    dispatch(setMetaDataForFlowJson({ ...response?.script }))
    dispatch(updatedUsedVariables(response?.script?.used_variables))
    dispatch(addScriptAndUpdateList(response?.script))
  })
  if (payload?.status) return null
  return { ...response, ...payload?.data }
})

export const setAuthIdToUpdateThunk = createAsyncThunk('stepDataV3/updateTokenId', async (payload, { dispatch }) => {
  dispatch(updateAppInfoState({ authIdToUpdateToken: payload }))
  return true
})

// this thunk sends request to ai to optimize code or  replace variables in code
export const askAiForFunctionThunk = createAsyncThunk('stepDataV3/askAiForFunctionThunk', async (payload, { dispatch, getState }) => {
  const { scriptId, stepId, slugName } = getInfoParamtersFromUrl()
  const stepInstance = getState()?.stepsDataV3?.[scriptId]?.[stepId]?.draft
  const context = filterObjectByKey(getState(), slugName, scriptId)
  const librarySupported = getState().appInfo?.librarySupported
  const purpose = payload?.purpose
  let userMessage = ''
  switch (purpose) {
    case 'optimize':
      userMessage = `Refactor the code to extract all values into a config object at the beginning of the code. Ensure that the rest of the code accesses these values exclusively through the config object.`
      break
    case 'variable update':
      userMessage =
        stepInstance?.dynamicVariables && Object.keys(stepInstance?.dynamicVariables).length
          ? `update the code according to this config json ${JSON.stringify(stepInstance?.dynamicVariables)}`
          : `correct only if needed`
      break
    default:
      break
  }

  const dataToSend = {
    user: userMessage,
    code: stepInstance?.code,
    result: stepInstance?.result?.message,
    context,
    libraries: processSupportedLibraryData(librarySupported),
    threadId: stepId
  }
  const response = await optimizeCodeByAI(API_URL.functionAskAi, dataToSend)
  // response function=> "{ code config } }"
  const parsedCode = JSON.parse(response || '{}')
  let config = {}
  try {
    if (parsedCode?.config) config = typeof parsedCode?.config === 'object' ? parsedCode?.config : JSON.parse(parsedCode?.config)
  } catch (e) {
    console.error('createAsyncThunk parser error', parsedCode?.config, e)
  }
  dispatch(
    setKeyValueInstance({
      code: parsedCode?.code || stepInstance?.code,
      hasUnsavedCode: true,
      id: stepId,
      dynamicVariables: config,
      variablesChanged: false,
      refreshDynamicVariables: false
    })
  )
  return { code: parsedCode?.code || stepInstance?.code, config }
})

/**
 *this function calls flow which calls a bride to update response statement, then another bride is called with its out come so two things are extracted html and value to set in response custom auto suggest.
 */
export const askAIforResponseBlockThunk = createAsyncThunk('stepDataV3/askAIforResponseBlockThunk', async (payload, { getState }) => {
  const { scriptId } = getInfoParamtersFromUrl()
  const context = getState().invocationV2?.[scriptId]?.context
  const title = getState().scripts?.scripts?.[scriptId]?.title || ''
  const description = getState().scripts?.scripts?.[scriptId]?.metadata?.description || ''

  const dataToSend = {
    user: payload?.code,
    code: payload?.code,
    result: payload?.testResult,
    context,
    title,
    description,
    threadId: `${scriptId}_response_block`
  }
  const response = await optimizeCodeByAI(API_URL.ResponseByAI, dataToSend)
  return JSON.parse(response)
})

export const askAItoCorrectAPIThunk = createAsyncThunk('stepDataV3/askAItoCorrectAPIThunk', async (payload, { dispatch, getState }) => {
  const { sectionIdOrScriptId, versionIdOrStepId, scriptId, pluginId } = getInfoParamtersFromUrl()
  const stepInstance = getState()?.stepsDataV3?.[sectionIdOrScriptId]?.[versionIdOrStepId]?.draft
  const context = getState().invocationV2?.[scriptId || pluginId]?.context

  const dataToSend = {
    bridgeId:
      stepInstance.type === BlockTypes.FUNCTION
        ? '66d196b9190e100685297d46'
        : stepInstance?.allowDynamicValues !== false
        ? '6618fb8ae29745d7c050840d'
        : '66a8c52956e88345b830a77c',
    user: 'correct this javascript code if possible or tell user the solution of api failure as message',
    code: stepInstance.type === BlockTypes.FUNCTION ? stepInstance.code : createCodeForAPI(stepInstance),
    result: payload?.response,
    context,
    threadId: versionIdOrStepId
  }
  const response = await optimizeCodeByAI(API_URL.apiSliderAskAi, dataToSend)
  const instanceToAppend =
    stepInstance.type === BlockTypes.FUNCTION ? { ...JSON.parse(response || '{}') } : getApiInstanceDataFromCode(response)
  dispatch(setKeyValueInstance({ aiHelpTextAndCode: instanceToAppend, id: versionIdOrStepId }))
  return { ...stepInstance, ...instanceToAppend }
})

export const generateResponseBlockWithAIOnPublishThunk = createAsyncThunk(
  'stepDataV3/generateResponseBlockWithAIOnPublishThunk',
  async (payload, { dispatch }) => {
    await dispatch(askAIforResponseBlockThunk({})).then((e) => {
      const value = e.payload?.conditionallyWrapped
      const html = e.payload?.fullyWrapped
      const dataToSend = {
        type: BlockTypes.RESPONSE,
        stepId: BlockTypes.RESPONSE,
        title: BlockTypes.RESPONSE,
        data: value,
        _data: html,
        responseType: 'custom',
        isAIGenerated: true
      }
      dispatch(createOrUpdateFunctionThunk({ data: dataToSend }))
    })
  }
)
