import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Avatar from '@mui/material/Avatar'
import { Switch, TextField, Typography, Box, Button, Container } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import { getScopesOfParticularAuth, getPluginData } from '../../api'
import { useFetchActionsTriggers, useFetchPlugins } from '../../react-query/allPluginsData/allPluginsDataQueries.ts'
import config from '../../config'
import { EventPageSkeleton } from '../../components/skeleton/eventPageSkeleton'
import { AuthData } from '../../types/Authdata.ts'

export function EventPage() {
  const { serviceId } = useParams()
  const queryParams = new URLSearchParams(window.location.search)
  const isUpdate = queryParams.get('isUpdate') === 'true'
  const authIdToUpdate = queryParams.get('authidtoupdatetoken')
  const navigate = useNavigate()
  const { state: locationState } = useLocation()
  const [searchAction, setSearchAction] = useState('')
  const [selectedPlugin, setSelectedPlugin] = useState(locationState?.pluginDetails || {}) // getting locationState?.pluginDetails from previous component [ServicePage]
  const { data: actionList, isLoading } = useFetchActionsTriggers(serviceId || '', 'both')
  const authrowid = queryParams.get('authrowid')
  const { data: pluginData, isLoading: isPluginLoading } = isUpdate
    ? useFetchPlugins(serviceId || '', [], [serviceId as string]) || {}
    : { isLoading: false }
  const [allActionList, setAllActionList] = useState([])

  const [selectedActions, setSelectedActions] = useState({}) // new state for selected actions
  const [unmatchingActions, setUnmatchingActions] = useState([])
  const [authInfo, setAuthInfo] = useState({})

  // calls initially to mark the actions selected if the authIdToUpdate is passed
  const getAuthTokenScopesAndUpdateState = async (allActionsLocal) => {
    const authData: AuthData = await getScopesOfParticularAuth(authIdToUpdate)
    const scopes = authData.scope || []

    const response = await getPluginData({ pluginrecordid: serviceId ?? '', rowid: authData.auth_version_id ?? '' }, 'auth')
    setAuthInfo(response)

    const actionsWithScopesInAuthData = allActionsLocal.filter((action: any) => {
      const actionScopes = action?.scopes?.split(response?.[0]?.scopeseperatedby === 'space' ? ' ' : ',') || []
      return actionScopes?.every((scope) => scopes.includes(scope)) && action?.authidlookup === authData.auth_version_id
    })

    const unmatchingActions = allActionsLocal.filter((action: any) => action.authidlookup !== authData.auth_version_id)
    setUnmatchingActions(unmatchingActions)

    const mapping = {}
    actionsWithScopesInAuthData.forEach((action: any) => {
      mapping[action.rowid] = action
    })
    setSelectedActions(mapping)
  }

  const filteredActions = Object.values(actionList || [])?.filter((action: any) =>
    action?.name?.toLowerCase()?.includes(searchAction?.toLowerCase())
  )

  useEffect(() => {
    const allActionsLocal = Object.values(actionList || [])?.filter((action: any) =>
      action?.name?.toLowerCase()?.includes(searchAction?.toLowerCase())
    )
    setAllActionList(actionList ? allActionsLocal : [])
    if (isUpdate && authIdToUpdate) {
      getAuthTokenScopesAndUpdateState(allActionsLocal)
    }
  }, [actionList])

  useEffect(() => {
    if (!isUpdate || isPluginLoading) return
    setSelectedPlugin(Object.values(pluginData || {})[0])
  }, [isPluginLoading])

  /**
   * Combines the scopes from selected actions and authentication information.
   *
   * This function aggregates scopes from the selected actions and the authentication information.
   * It first determines the separator to use for splitting scopes based on the `scopeseperatedby` property.
   * Then, it collects scopes from the selected actions and the authentication information,
   * removes duplicates, and returns the combined scopes as a single string.
   *
   * @returns {string} A string of unique combined scopes separated by the determined separator.
   */
  const getAuthAndActionScopesCombined = () => {
    let combinedScopes = []
    const scopesSeperator = authInfo?.[0]?.scopeseperatedby === 'space' ? ' ' : ','
    const selectedActionArray = Object.values(selectedActions)
    combinedScopes =
      selectedActionArray?.length > 0
        ? selectedActionArray.reduce((acc, obj) => {
            return acc.concat(obj?.scopes?.split(scopesSeperator) || []) // scope separator is used to separate from different array and to remove duplicacy of the scopes
          }, [])
        : []
    if (authInfo?.[0]?.scopes) {
      combinedScopes = combinedScopes.concat(authInfo[0].scopes?.split(scopesSeperator))
    }
    const uniqueScopes = Array.from(new Set(combinedScopes))
    return uniqueScopes.join(scopesSeperator)
  }

  const searchActionData = (e) => {
    setSearchAction(e.target.value)
  }
  /**
   * This function handles the authorization of selected actions.
   * It constructs a URL with the necessary query parameters and navigates to it.
   * The URL includes the service ID and the authentication ID of the first selected action.
   * The function also logs the combined scopes, backButton state, and action ID for debugging purposes.
   *
   * @returns {void}
   */

  const AuthorizeActions = () => {
    // const rowids = state.selectedActions.map((obj) => obj?.actionversionrecordid).join(',')
    const rowids = Object.values(selectedActions)
      ?.map((obj) => obj?.rowid)
      .join(',')
    let url = `/auth/service/${serviceId}/auth/${Object.values(selectedActions)[0]?.authidlookup}`
    const { search } = window.location
    const actionid = rowids
    const queryParams = new URLSearchParams(search)

    queryParams.set('actionid', actionid)
    if (queryParams.toString()) {
      url += `?${queryParams.toString()}`
    }

    navigate(url, {
      state: {
        scopes: getAuthAndActionScopesCombined(),
        backButton: true,
        actionId: Object.values(selectedActions)[0]?.actionversionrecordid
      }
    })
  }

  /**
   * This function handles the authorization of selected actions.
   * It constructs a URL with the necessary query parameters and navigates to it.
   * The URL includes the service ID and the authentication ID of the first selected action.
   * The function also logs the combined scopes, backButton state, and action ID for debugging purposes.
   *
   * @returns {void}
   */
  const handleBackButton = async () => {
    if (isUpdate) return
    let url = config.servicePage
    const { search } = window.location
    if (search) {
      url += search
    }
    navigate(url)
  }

  /**
   * Handles the selection and deselection of actions for authentication.
   * When an action is selected, it fetches the corresponding authentication data,
   * updates the state with the fetched data, and determines which other actions
   * match or do not match the selected action based on their scopes.
   *
   * @param {object} object - The action object that was clicked.
   */
  const onSelectAction = async (action) => {
    if (unmatchingActions?.some((item) => item?.rowid === action?.rowid)) return

    const updatedSelectedActions = { ...selectedActions }

    if (Object.prototype.hasOwnProperty.call(selectedActions, action.rowid)) {
      delete updatedSelectedActions[action.rowid]
    } else {
      updatedSelectedActions[action.rowid] = action
    }
    setSelectedActions(updatedSelectedActions)

    let unmatchingActionsLocal = unmatchingActions
    if (Object.keys(updatedSelectedActions)?.length === 0) {
      if (isUpdate && authIdToUpdate) {
        unmatchingActionsLocal = allActionList.filter((currentAction: any) => currentAction.authidlookup !== authrowid)
      } else {
        unmatchingActionsLocal = []
      }
    } else {
      unmatchingActionsLocal = allActionList.filter((currentAction: any) => currentAction.authidlookup !== action.authidlookup)
    }
    setUnmatchingActions(unmatchingActionsLocal)

    const response = await getPluginData({ pluginrecordid: serviceId ?? '', rowid: action?.authidlookup ?? '' }, 'auth')
    setAuthInfo(response)
  }

  return (
    <Container className='p-4' maxWidth='md'>
      <Box className='flex-col gap-3 w-100  h-100'>
        <>
          {/* <ConnectionBreadCrumbs/> */}
          <Box className='flex-col gap-5'>
            <Typography variant='h4'>{isUpdate ? 'Update' : 'Add New'} Connection</Typography>
            {queryParams.get('mode') !== 'embed' && (
              <Box
                className='flex-start-center w-fitcontent p-2 '
                onClick={() => {
                  handleBackButton()
                }}
              >
                {!isUpdate && <ArrowBackIosIcon fontSize='small' className='color-grey' />}
                <Avatar
                  alt={selectedPlugin?.name}
                  src={selectedPlugin?.iconurl}
                  sx={{ height: '25px', width: '25px' }}
                  className='ml-3 mr-3 '
                />
                <Typography variant='h6' className='color-grey'>
                  {' '}
                  {selectedPlugin?.name}
                </Typography>
              </Box>
            )}
          </Box>
          <Typography variant='h5' className='mb-2 '>
            {' '}
            Select all the actions that you want to authenticate
          </Typography>
        </>
        <Box className='mb-3'>
          <TextField
            size='small'
            width='small'
            id='services'
            name='services'
            type='text'
            label='Search actions'
            variant='outlined'
            onChange={searchActionData}
            autoFocus
          />
        </Box>
        <Box className='flex h-100 w-100 flex-wrap '>
          {isLoading && <EventPageSkeleton />}
          {filteredActions.map((object) => (
            <Box
              className='p-3 flex-spaceBetween-center cursor-pointer authdatabox mb-3 mr-3'
              onClick={() => onSelectAction(object)}
              key={object?.rowid}
            >
              <Typography variant='h7' className={unmatchingActions?.some((item) => item?.rowid === object?.rowid) ? 'opacity-06' : ''}>
                {object.name}
              </Typography>
              {!unmatchingActions?.some((item) => item?.rowid === object?.rowid) && (
                <Switch
                  size='medium'
                  checked={Object.prototype.hasOwnProperty.call(selectedActions, object?.rowid) || false}
                  color='success'
                  className='ml-3'
                />
              )}
            </Box>
          ))}
          {filteredActions?.length === 0 && !isLoading && <Typography variant='h3'>No data found</Typography>}
        </Box>
        <Button
          variant='contained'
          endIcon={<ArrowForwardIcon />}
          onClick={AuthorizeActions}
          disabled={!Object.keys(selectedActions).length}
        >
          NEXT
        </Button>
      </Box>
    </Container>
  )
}
